var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('h1',{staticClass:"my-auto"},[_vm._v("Liste des comptes")]),_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add-user",modifiers:{"modal-add-user":true}}],attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Ajouter un Utilisateur")])],1)],1)])]),(!_vm.loading)?_c('b-card',[_c('ListUtilisateursComponent')],1):_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1),_c('b-modal',{ref:"modal-add-user",attrs:{"id":"modal-add-user","cancel-variant":"outline-secondary","centered":"","title":"Nouvel Utilisateur","hide-footer":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastname"}},[_vm._v("Nom:")]),_c('b-form-input',{attrs:{"id":"lastname","type":"text","placeholder":"Le nom de l'utilisateur. Exemple : CHITOU"},model:{value:(_vm.newUser.lastname),callback:function ($$v) {_vm.$set(_vm.newUser, "lastname", $$v)},expression:"newUser.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstname"}},[_vm._v("Prénom(s) : ")]),_c('b-form-input',{attrs:{"id":"firstname","type":"text","placeholder":"Le prénom de l'utilisateur. Exemple : Jean"},model:{value:(_vm.newUser.firstname),callback:function ($$v) {_vm.$set(_vm.newUser, "firstname", $$v)},expression:"newUser.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v("Email : ")]),_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"L'email de l'utilisateur. Exemple : chitou.jean@gmail.com"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"roles"}},[_vm._v("Roles : ")]),_c('v-select',{attrs:{"id":"roles","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nom","reduce":function (option) { return option; },"placeholder":"Choisissez le rôle de l'utilisateur; Exemple: Agent MTCA","options":_vm.roles},on:{"change":function($event){return _vm.on_role_change()}},model:{value:(_vm.newUser.role),callback:function ($$v) {_vm.$set(_vm.newUser, "role", $$v)},expression:"newUser.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.newUser.role && _vm.newUser.role.code == 'agent_ddtca')?_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"roles"}},[_vm._v("Département associé:")]),_c('v-select',{attrs:{"placeholder":"Choisir un département","clearable":true,"options":_vm.departementsList},model:{value:(_vm.newUser.associated_department),callback:function ($$v) {_vm.$set(_vm.newUser, "associated_department", $$v)},expression:"newUser.associated_department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1635306966)})],1):_vm._e()],1)],1),_c('hr'),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","disabled":!_vm.newUser.lastname},on:{"click":function($event){return _vm.registerUser()}}},[(_vm.loadingAddUser)?_c('b-spinner',{attrs:{"variant":"primary my-auto","small":""}}):_vm._e(),_vm._v(" Enregistrer ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }