<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex flex-wrap justify-content-between">
        <h1 class="my-auto">Liste des comptes</h1>
        <div>
          <b-button v-b-modal.modal-add-user variant="primary">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Ajouter un Utilisateur</span>
          </b-button>
        </div>
      </div>
      <!-- <div class="d-lg-none">
        <h1>hide on lg and wider screens</h1>
      </div>
      <div class="d-none d-lg-block">
        <h1>hide on screens smaller than lg</h1>
      </div> -->
    </b-card>

    <b-card v-if="!loading">
      <ListUtilisateursComponent />
    </b-card>
    <div v-else class="text-center">
      <b-spinner variant="primary" label="Text Centered" />
    </div>

    <!-- add new admin -->
    <b-modal
      id="modal-add-user"
      ref="modal-add-user"
      cancel-variant="outline-secondary"
      centered
      title="Nouvel Utilisateur"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="formRules">
        <b-form>
          <!-- Nom -->
          <b-form-group>
            <validation-provider v-slot="{ errors }" rules="required">
              <label for="lastname">Nom:</label>
              <b-form-input
                id="lastname"
                v-model="newUser.lastname"
                type="text"
                placeholder="Le nom de l'utilisateur. Exemple : CHITOU"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Prénom -->
          <b-form-group>
            <validation-provider v-slot="{ errors }" rules="required">
              <label for="firstname">Prénom(s) : </label>
              <b-form-input
                id="firstname"
                v-model="newUser.firstname"
                type="text"
                placeholder="Le prénom de l'utilisateur. Exemple : Jean"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Email -->
          <b-form-group>
            <validation-provider v-slot="{ errors }" rules="required">
              <label for="email">Email : </label>
              <b-form-input
                id="email"
                v-model="newUser.email"
                type="email"
                placeholder="L'email de l'utilisateur. Exemple : chitou.jean@gmail.com"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Roles -->
          <b-form-group>
            <validation-provider v-slot="{ errors }" rules="required">
              <label for="roles">Roles : </label>
              <v-select
                id="roles"
                v-model="newUser.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nom"
                :reduce="(option) => option"
                placeholder="Choisissez le rôle de l'utilisateur; Exemple: Agent MTCA"
                :options="roles"
                @change="on_role_change()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Département -->
          <b-form-group v-if="newUser.role && newUser.role.code == 'agent_ddtca'">
            <validation-provider v-slot="{ errors }" rules="required">
              <label for="roles">Département associé:</label>
              <v-select
                v-model="newUser.associated_department"
                placeholder="Choisir un département"
                :clearable="true"
                :options="departementsList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
      <hr />
      <div class="d-flex flex-row-reverse">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="registerUser()"
          :disabled="!newUser.lastname"
        >
          <b-spinner v-if="loadingAddUser" variant="primary my-auto" small />
          Enregistrer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ListUtilisateursComponent from "@/views/apps/utilisateurs/administration/component/ListUtilisateursComponent.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import utilsService from "@/services/utils/utils.service";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import rolesStoreModule from "@/store/roles";
import usersStoreModule from "@/store/users";
import agentGouvenementStoreModule from "@/store/agent-gouvenement";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  name: "UsersList",
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
      { path: "roles", module: rolesStoreModule },
      { path: "agentGouvenement", module: agentGouvenementStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    const departementsList = utilsService.getDepartements();
    const communesList = utilsService.getCommunes()

    return { requiredStoreModules, departementsList, communesList };
  },

  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,

    ListUtilisateursComponent,

    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  data() {
    return {
      tableData: [],
      filters: { options: {} },
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200, 500],
      newUser: {
        firstname: null,
        lastname: null,
        email: null,
        role: null,
        isResetPassord: true,
        associated_department: null
      },
      selected_role: null,
      roles: [],
      loading: false,
      loadingAddUser: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.getListRole();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  async mounted() { },
  methods: {
    ...mapActions("users", {
      action_fetchUsers: "fetchUsers",
      addUser: "addUser",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("etablissements", {
      getAgent: "getAgent",
      fetchEtablissements: "fetchEtablissements",
    }),
    ...mapActions("roles", { fetchRoles: "fetchRoles" }),
    ...mapActions("agentGouvenement", {
      createAgentGouvenement: "createAgentGouvenement",
      findAgentGouvenement: "findAgentGouvenement",
    }),
    // ---------------------------------------------------------------------
    async getListRole() {
      this.fetchRoles()
        .then(async (response) => {
          this.roles = response.data.resources || [];
          // Filtrer les objets de façon unique en fonction de l'attribut "code"
          const objetsUniques = Array.from(new Set(this.roles.map(a => a.code))).map(code => {
            return this.roles.find(a => a.code === code);
          });
          this.roles = objetsUniques
        })
        .catch((err) => {
          console.log('err::: ', err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    registerUser() {
      this.$refs.formRules.validate().then(async (success) => {
        if (success) {
          if (this.loadingAddUser) return;

          this.loadingAddUser = true;

          // Execute reCAPTCHA with action "submit".
          const token = await this.$recaptcha("submit");
          if (!token) {
            this.loadingAddUser = false;
            return;
          }
          this.newUser.token = token;
          this.newUser.role = this.newUser.role._id

          // end load role
          this.addUser(this.newUser)
            .then(async (res) => {
              this.loadingAddUser = false;
              this.$refs["modal-add-user"].hide();
              (this.newUser = {
                firstname: null,
                lastname: null,
                email: null,
                role: null,
              }),
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Opération réussie",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
            })
            .catch((err) => {
              this.loadingAddUser = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.message || "Une erreur est survenue",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    on_role_change(data){
      console.log('role change ::: ❌', data);
    }
  },
};
</script>
<style lang="scss" scoped></style>
